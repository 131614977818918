

.node {
   /* border: 1px solid #6396e3;*/
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    display:flex;
    flex-direction:column;
    padding:5px;
    padding-left:15px;
}

.node .label-name {
    color: #73777d;
    text-align: center;
    /* Body/Tiny/300 */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 133.333% */
    width: 82px;
    text-align: center;
    padding-top: 5px;
}

.node-port {
    width: 13px;
    height: 13px;
    border-radius: 10px;
    background-color: #a4a9fc;
    cursor: pointer;
    position: relative;
    z-index: -2;
}

.node-header-container {
    width: 100%;
    height: 25px;
    text-align: left;
    font-weight: bold;
    position: absolute;
    top: 0;
    display: flex;
}

.node-header-text {
    color: gray;
    font-size: 13px;
    padding-left: 3px;
    margin-top: 4px;
}

.node-content {
    color: #6a6b6b;
    font-size: 11px;
    text-align: left;
    width: 80%;
    margin: 0 auto;
    margin-top: 32px;
}

.port-container {
    position: absolute;
    top: 40px;
}

.left-port {
    left: 0;
    margin-left: -4px;
}

.right-port {
    right: 0;
    margin-right: -11px;
}

.my-icon {
    margin-top: 3px;
    margin-left: 4px;
}
