.node-type-label {
    color: black;
    font-family: Helvetica, Arial;
    font-weight: bold;
    padding: 5px;
    margin: 0px 10px;
    border-radius: 3px;
    margin-bottom: 5px;
    cursor: pointer;
    width:50px;
}

    .node-type-label .label-name {
        color: #73777d;
        text-align: center;
        /* Body/Tiny/300 */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px; /* 133.333% */
        width: 100px;
        text-align: center;
        padding-top: 5px;
    }