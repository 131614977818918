.report-writer-tab {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.report-writer-tab__report-section {
  width: 50%;
}

.layout-empty {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-writer-tab__audit-report {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding: 24px 8px 24px 24px;
}

.report-writer-tab__report-section {
  padding: 24px;
}

.audit-report-for {
  display: flex; 
  align-items: center;
  margin: 8px 0;
}

.report-writer-tab_selector {
  padding: 16px 8px 16px 0;
  margin-bottom: 16px;
}

.sections-header,
.report-items-header {
  margin-top: 0;
}

.report-section__header,
.report-section_navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-section__header p {
  margin: 8px 0;
}

.report-writer_sections,
.report-writer__report-items {
  width: 40%;
}

.report-section_writing {
  padding: 16px;
  height: 76%;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  flex-direction: column;
}

.no-rows {
  font-size: 0.85rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}