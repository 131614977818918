.hidden-delete-btn {
    display: none;
}

.pointer-cursor {
    cursor: pointer
}

.card-list:hover .hidden-delete-btn {
    display: block;
}

.edit-text-disabled {
    cursor: not-allowed;
}

.display-parse-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.delete-btn {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.card-list:hover .delete-btn {
    visibility: visible;
    opacity: 1;
}

.report-section__writing {
    height: 400px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 16px;
    margin-top: 8px;
}

.drag-handle {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.card-list:hover .drag-handle {
    visibility: visible;
    opacity: 1;
}