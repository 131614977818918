@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight&display=swap");
html,
body,
#root,
.app,
.content {
  font-family: Inter, sans-serif;
}

body {
  font-family: Inter, sans-serif;
  background-color: rgb(216, 216, 216) !important;
  margin: 0;
  overflow: overlay;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  background-clip: content-box;
  border: 2px solid transparent;
}

body[scroll]::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.w-400 {
  width: 400px;
}

.w-350 {
  width: 350px;
}

.w-300 {
  width: 300px;
}

.w-full {
  width: 100%;
}

.ml-3 {
  margin-left: 30px;
}

.ml-5 {
  margin-left: 50px;
}

.pl-3 {
  padding-left: 30px !important;
}

.pl-5 {
  padding-left: 50px !important;
}

.overwrite-table .MuiDataGrid-columnHeaders {
  background-color: white;
  color: black;
  border-bottom: "none";
}
.overwrite-table .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
  background-color: white;
}
.overwrite-table .MuiDataGrid-footerContainer {
  background-color: white;
  color: black;
}
.overwrite-table .css-hpjhlg-MuiTablePagination-root, .overwrite-table svg {
  color: black;
}
.overwrite-table .MuiDataGrid-toolbarContainer .MuiButton-text {
  margin-bottom: 10px;
  margin-right: 21px;
  padding: 5px 12px;
  border: 1px solid #141414;
}
.overwrite-table .css-1j9kmqg-MuiDataGrid-toolbarContainer {
  padding-left: 0px;
}

.site-text {
  color: #25282E !important;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.site-text-bold {
  font-weight: 600 !important;
}

.export-button {
  border: 1px solid black;
  padding: 8px 15px;
  color: #040509;
  text-decoration: unset;
  background-color: white;
  border-radius: 5px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.75;
  text-transform: uppercase;
  transition: 1s;
}
.export-button:hover {
  transition: 1s;
  background-color: rgba(4, 5, 9, 0.04);
}

.site-text {
  color: #25282E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.site-text span {
  color: #25282E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.ul-list ul {
  margin: 0;
  padding: 0;
}
.ul-list ul li {
  font-weight: 600;
  padding: 10px;
  list-style: none;
  border: 1px solid #e0e0e0;
  display: flex;
}
.ul-list ul li label {
  min-width: 100px;
  display: block;
}

.text-normal {
  font-weight: normal !important;
}

.highlight-row {
  background-color: #dddbdb;
}
